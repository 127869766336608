import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124')
];

export const server_loads = [0,3,6];

export const dictionary = {
		"/": [~8],
		"/(web)/about": [19],
		"/authenticated/social/posts": [42],
		"/(web)/contact": [~20],
		"/(web)/contact/services/request-domain-registration": [~21],
		"/(web)/documentation": [22],
		"/(auth)/error": [13],
		"/(web)/help": [23],
		"/(auth)/login": [~14],
		"/(auth)/logout": [15],
		"/(web)/market": [24],
		"/(web)/privacy": [~25],
		"/(a-z)/private/games": [9,[2]],
		"/(a-z)/private/games/simongame": [10,[2]],
		"/(a-z)/private/games/spacewordle": [11,[2]],
		"/(a-z)/private/pageServerLoadToClient": [~12],
		"/(web)/services": [26],
		"/(web)/services/analytics": [27],
		"/(web)/services/design": [28],
		"/(web)/services/design/images": [29],
		"/(web)/services/design/static-blog": [30],
		"/(web)/services/design/storefront": [31],
		"/(web)/services/development": [32],
		"/(web)/services/hosting": [33],
		"/(web)/services/hosting/check-domain-availability": [~34],
		"/(web)/services/hosting/cpanel": [~35],
		"/(web)/services/hosting/plans": [~36],
		"/(web)/shop": [~37],
		"/(auth)/signin": [16],
		"/(auth)/signout": [17],
		"/(web)/terms": [~38],
		"/(web)/testing": [~39],
		"/(web)/testing/authorize": [~40],
		"/(web)/testing/products": [~41],
		"/users/v0.1.beta/control-panel": [120,[3]],
		"/users/v0.1.beta/favorites": [121,[3]],
		"/users/v0.1.beta/intro": [~122,[3]],
		"/users/v0.1.beta/profile": [123,[3]],
		"/users/v0.1.beta/services": [124,[3]],
		"/users/v0.1/admin": [43,[3,4]],
		"/users/v0.1/admin/customers": [~44,[3,4]],
		"/users/v0.1/admin/customers/acceptsuite": [~45,[3,4]],
		"/users/v0.1/admin/customers/customerprofiles": [~46,[5]],
		"/users/v0.1/admin/customers/customerprofiles/createacustomerprofilefromatransaction": [~47,[5]],
		"/users/v0.1/admin/customers/customerprofiles/createcustomerpaymentprofile": [~48,[5]],
		"/users/v0.1/admin/customers/customerprofiles/createcustomerprofile": [~49,[5]],
		"/users/v0.1/admin/customers/customerprofiles/createcustomershippingaddress": [~50,[5]],
		"/users/v0.1/admin/customers/customerprofiles/deletecustomerpaymentprofile": [~51,[5]],
		"/users/v0.1/admin/customers/customerprofiles/deletecustomerprofile": [~52,[5]],
		"/users/v0.1/admin/customers/customerprofiles/deletecustomershippingaddress": [~53,[5]],
		"/users/v0.1/admin/customers/customerprofiles/getcustomerpaymentprofilelist": [~55,[5]],
		"/users/v0.1/admin/customers/customerprofiles/getcustomerpaymentprofile": [~54,[5]],
		"/users/v0.1/admin/customers/customerprofiles/getcustomerprofileids": [~57,[5]],
		"/users/v0.1/admin/customers/customerprofiles/getcustomerprofile": [56,[5]],
		"/users/v0.1/admin/customers/customerprofiles/getcustomershippingaddress": [~58,[5]],
		"/users/v0.1/admin/customers/customerprofiles/updatecustomerpaymentprofile": [~59,[5]],
		"/users/v0.1/admin/customers/customerprofiles/updatecustomerprofile": [~60,[5]],
		"/users/v0.1/admin/customers/customerprofiles/updatecustomershippingaddress": [~61,[5]],
		"/users/v0.1/admin/customers/customerprofiles/validatecustomerpaymentprofile": [~62,[5]],
		"/users/v0.1/admin/customers/fraudmanagement": [~63,[3,4]],
		"/users/v0.1/admin/customers/mobileinapptransactions": [~64,[3,4]],
		"/users/v0.1/admin/customers/paymenttransactions": [~65,[3,4]],
		"/users/v0.1/admin/customers/paypalexpresscheckout": [~66,[3,4]],
		"/users/v0.1/admin/customers/recurringbilling": [~67,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting": [~68,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting/accountupdaterjobdetails": [~69,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting/accountupdaterjobsummary": [~70,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting/batchstatistics": [~71,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting/customerprofiletransactionlist": [~72,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting/getmerchantdetails": [~73,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting/gettransactionlist": [~74,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting/settledbatchlist": [~75,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting/transactiondetails": [~76,[3,4]],
		"/users/v0.1/admin/customers/transactionreporting/unsettledtransactionlist": [~77,[3,4]],
		"/users/v0.1/admin/dashboard": [~78,[3,4]],
		"/users/v0.1/admin/dashboard/gamers": [79,[3,4]],
		"/users/v0.1/admin/dashboard/news-subscribers": [80,[3,4]],
		"/users/v0.1/admin/dashboard/posts": [~81,[3,4]],
		"/users/v0.1/editor": [82,[3,6]],
		"/users/v0.1/user/account": [~83,[3,7]],
		"/users/v0.1/user/account/addresses": [~84,[3,7]],
		"/users/v0.1/user/account/addresses/delete": [~85,[3,7]],
		"/users/v0.1/user/account/addresses/edit": [~86,[3,7]],
		"/users/v0.1/user/account/paymentmethods": [87,[3,7]],
		"/users/v0.1/user/account/profile": [~88,[3,7]],
		"/users/v0.1/user/billing": [~89,[3,7]],
		"/users/v0.1/user/billing/history": [90,[3,7]],
		"/users/v0.1/user/billing/invoices": [~91,[3,7]],
		"/users/v0.1/user/billing/paymentprofile": [~92,[3,7]],
		"/users/v0.1/user/billing/subscriptions": [~93,[3,7]],
		"/users/v0.1/user/blog": [~94,[3,7]],
		"/users/v0.1/user/blog/posts/archive": [~95,[3,7]],
		"/users/v0.1/user/blog/posts/create": [~96,[3,7]],
		"/users/v0.1/user/blog/posts/drafts": [~97,[3,7]],
		"/users/v0.1/user/blog/posts/edit/[id]": [~98,[3,7]],
		"/users/v0.1/user/blog/posts/view/[id]": [~99,[3,7]],
		"/users/v0.1/user/dashboard": [~100,[3,7]],
		"/users/v0.1/user/dashboard/calendars": [101,[3,7]],
		"/users/v0.1/user/dashboard/contacts": [102,[3,7]],
		"/users/v0.1/user/dashboard/mails": [103,[3,7]],
		"/users/v0.1/user/notifications": [104,[3,7]],
		"/users/v0.1/user/notifications/read": [105,[3,7]],
		"/users/v0.1/user/notifications/settings": [106,[3,7]],
		"/users/v0.1/user/notifications/unread": [107,[3,7]],
		"/users/v0.1/user/settings": [108,[3,7]],
		"/users/v0.1/user/settings/general": [109,[3,7]],
		"/users/v0.1/user/settings/privacy": [110,[3,7]],
		"/users/v0.1/user/settings/security": [111,[3,7]],
		"/users/v0.1/zones": [112,[3]],
		"/users/v0.1/zones/how-to-center": [113,[3]],
		"/users/v0.1/zones/resources": [114,[3]],
		"/users/v0.1/zones/resources/documentation": [115,[3]],
		"/users/v0.1/zones/support": [116,[3]],
		"/users/v0.1/zones/support/contact-support": [117,[3]],
		"/users/v0.1/zones/troubleshooting": [118,[3]],
		"/users/v0.1/zones/tutorials": [119,[3]],
		"/(auth)/verify-email": [18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';